<template>
    <div class="auth-wrapper auth-v1 px-2">
        <div class="auth-inner py-2">
            <b-overlay :show="loading"
                       :variant="variant"
                       :opacity="opacity"
                       :blur="blur"
                       spinner-variant="primary"
                       spinner-type="grow"
                       spinner-large
                       rounded="sm">

                <b-card class="mb-0">
                    <b-link class="brand-logo">
                        <vuexy-logo />
                        <!--<h2 class="brand-text text-primary ml-1">
                            Landingly
                        </h2>-->
                    </b-link>

                    <!--<b-card-title class="mb-1">
                        Adventure starts here 🚀
                    </b-card-title>
                    <b-card-text class="mb-2">
                        Make your app management easy and fun!
                    </b-card-text>-->
                    <!-- form -->
                    <validation-observer ref="registerForm"
                                         #default="{invalid}">
                        <b-form class="auth-register-form mt-2"
                                @submit.prevent="register">
                            <!-- username -->
                            <b-form-group label="First Name"
                                          label-for="register-Firstname">
                                <validation-provider #default="{ errors }"
                                                     name="Firstname"
                                                     vid="firstname"
                                                     rules="required">
                                    <b-form-input id="register-firstname"
                                                  v-model="firstname"
                                                  name="register-firstname"
                                                  :state="errors.length > 0 ? false:null"
                                                  placeholder="please enter your first name" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <b-form-group label="Last Name"
                                          label-for="register-lastname">
                                <validation-provider #default="{ errors }"
                                                     name="Lastname"
                                                     vid="lastname"
                                                     rules="required">
                                    <b-form-input id="register-lastname"
                                                  v-model="lastname"
                                                  name="register-lastname"
                                                  :state="errors.length > 0 ? false:null"
                                                  placeholder="please enter your last name" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>


                            <!-- email -->
                            <b-form-group label="Email"
                                          label-for="register-email">
                                <validation-provider #default="{ errors }"
                                                     name="Email"
                                                     vid="email"
                                                     rules="required|email">
                                    <b-form-input id="register-email"
                                                  v-model="userEmail"
                                                  name="register-email"
                                                  :state="errors.length > 0 ? false:null"
                                                  placeholder="john@example.com" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- password -->
                            <b-form-group label-for="register-password"
                                          label="Password">
                                <validation-provider #default="{ errors }"
                                                     name="Password"
                                                     vid="password"
                                                     rules="required">
                                    <b-input-group class="input-group-merge"
                                                   :class="errors.length > 0 ? 'is-invalid':null">
                                        <b-form-input id="register-password"
                                                      v-model="password"
                                                      class="form-control-merge"
                                                      :type="passwordFieldType"
                                                      :state="errors.length > 0 ? false:null"
                                                      name="register-password"
                                                      placeholder="············" />
                                        <b-input-group-append is-text>
                                            <feather-icon :icon="passwordToggleIcon"
                                                          class="cursor-pointer"
                                                          @click="togglePasswordVisibility" />
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <b-form-group label-for="register-confirmpassword"
                                          label="Confirm Password">
                                <validation-provider #default="{ errors }"
                                                     name="Confirmpassword"
                                                     vid="confirmpassword"
                                                     rules="required">
                                    <b-input-group class="input-group-merge"
                                                   :class="errors.length > 0 ? 'is-invalid':null">
                                        <b-form-input id="register-confirmpassword"
                                                      v-model="confirmpassword"
                                                      class="form-control-merge"
                                                      :type="passwordFieldType"
                                                      :state="errors.length > 0 ? false:null"
                                                      name="register-confirmpassword"
                                                      placeholder="············" />
                                        <b-input-group-append is-text>
                                            <feather-icon :icon="passwordToggleIcon"
                                                          class="cursor-pointer"
                                                          @click="togglePasswordVisibility" />
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <b-form-group label="Sub Domain"
                                          label-for="register-subdomain">
                                <validation-provider #default="{ errors }"
                                                     name="Subdomain"
                                                     vid="subdomain"
                                                     rules="required">
                                    <b-form-input id="register-subdomain"
                                                  v-model="subdomain"
                                                  name="register-subdomain"
                                                  :state="errors.length > 0 ? false:null"
                                                  placeholder="please enter your sub domain" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <b-form-group>
                                <b-form-checkbox id="register-privacy-policy"
                                                 v-model="status"
                                                 name="checkbox-1">
                                    I agree to
                                    <b-link>privacy policy & terms</b-link>
                                </b-form-checkbox>
                            </b-form-group>

                            <b-button variant="primary"
                                      block
                                      type="submit"
                                      :disabled="invalid">
                                Sign up
                            </b-button>
                        </b-form>
                    </validation-observer>

                    <p class="text-center mt-2">
                        <span>Already have an account?</span>
                        <b-link :to="{name:'auth-login'}">
                            <span>&nbsp;Sign in instead</span>
                        </b-link>
                    </p>


                </b-card>

            </b-overlay>
        </div>
    </div>
</template>

<script>
    /* eslint-disable global-require */
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import VuexyLogo from '@core/layouts/components/Logo.vue'
    import {
        BCard, BLink, BCardTitle, BCardText, BForm,
        BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BFormCheckbox, BOverlay,
    } from 'bootstrap-vue'
    import { required, email } from '@validations'
    import { togglePasswordVisibility } from '@core/mixins/ui/forms'
    import store from '@/store/index'
    import useJwt from '@/auth/jwt/useJwt'

    export default {
        components: {
            VuexyLogo,
            // BSV
            BCard,
            BLink,
            BCardTitle,
            BCardText,
            BForm,
            BButton,
            BFormInput,
            BFormGroup,
            BInputGroup,
            BInputGroupAppend,
            BFormCheckbox,
            // validations
            ValidationProvider,
            ValidationObserver,
            BOverlay
        },
        mixins: [togglePasswordVisibility],
        data() {
            return {
                loading: false,
                variant: 'light',
                opacity: 0.85,
                blur: '2px',
                status: '',
                firstname: '',
                lastname: '',
                userEmail: '',
                password: '',
                confirmpassword: '',
                subdomain: '',
                sideImg: require('@/assets/images/pages/register-v2.svg'),
                // validation
                required,
                email,
            }
        },
        computed: {
            passwordToggleIcon() {
                return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
            },
            imgUrl() {
                if (store.state.appConfig.layout.skin === 'dark') {
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
                    return this.sideImg
                }
                return this.sideImg
            },
        },
        methods: {
            register() {
                this.$refs.registerForm.validate().then(success => {
                    if (success) {
                        this.loading = true;

                        useJwt.register({
                            firstname: this.firstname,
                            lastname: this.lastname,
                            email: this.userEmail,
                            password: this.password,
                            confirmpassword: this.confirmpassword,
                            subdomain: this.subdomain,
                            countryid: 160,
                        })
                            .then(response => {
                                //useJwt.setToken(response.data.accessToken)
                                //useJwt.setRefreshToken(response.data.refreshToken)
                                //  localStorage.setItem('atadresu', JSON.stringify(response.data.userData))
                                //this.$ability.update(response.data.userData.ability)
                                this.$router.replace('/login')
                                this.loading = false;
                            })
                            .catch(error => {
                                console.log("Error Message:: " + error)
                                this.$refs.registerForm.setErrors(error.response.data.error)
                                this.loading = false;
                            })
                    }
                })
            },
        },
    }
/* eslint-disable global-require */
</script>

<style lang="scss">
    @import '@core/scss/vue/pages/page-auth.scss';
</style>
